<template>
  <div>
    <div
      class="slider product-slider pb-12"
      :class="this.cols == 'auto' ? 'slider-auto' : 'product-grid'"
      v-if="products && products.length > 0"
    >
      <slot><h2 v-html="title"></h2></slot>
      <swiper :options="swiperOption" ref="swiperRef">
        <swiper-slide v-if="hasFirstCardSlot">
          <slot name="firstCard" class="firstCard" />
        </swiper-slide>
        <swiper-slide v-for="product in products" :key="product.productId">
          <ProductCard
            v-bind:product="product"
            @selectProduct="
              $emit('selectProduct', { product: product, index: index })
            "
          />
        </swiper-slide>
      </swiper>
      <div
        v-if="showBullets && products.length > 1"
        :id="`product-list-pagination-${paginationClass}`"
        class="swiper-pagination"
        @click="startAutoplay"
      ></div>
      <div
        v-if="showArrows && products.length > 1"
        :id="`product-list-slider-prev-${paginationClass}`"
        class="swiper-button-prev"
        @click="startAutoplay"
      ></div>
      <div
        v-if="showArrows && products.length > 1"
        :id="`product-list-slider-next-${paginationClass}`"
        class="swiper-button-next"
        @click="startAutoplay"
      ></div>
      <div v-if="showMore" class="text-right show-more">
        <v-btn
          link
          text
          class="primary--text text-none "
          :to="
            categoryPath
              ? {
                  name: 'Category',
                  params: { pathMatch: categoryPath },
                  query: { categoryId: categoryId }
                }
              : {
                  name: 'Category',
                  params: {
                    pathMatch: proposal.slug
                  }
                }
          "
        >
          mostra tutti <v-icon>$chevronRight</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<style global lang="scss">
.show-more {
  .v-btn {
    margin-right: -4px;
  }
}
.product-slider {
  .proposal-img {
    margin-bottom: 10px;
  }
  .banner-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .proposal-card {
    width: 220px;
    height: 380px;
    .v-card__text {
      width: auto;
    }
    img {
      padding: 0px;
      align-self: center;
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-card {
      .v-image {
        min-height: 110px;
      }
    }
    .proposal-card {
      height: 285px;
      width: 170px;
      .v-card__text {
        font-size: 11px;
        padding: 0 5px;
      }
    }
  }
  .firstCard {
    float: left;
  }
}
</style>
<script>
import ProductCard from "@/components/product/ProductCard.vue";
import ProductService from "~/service/productService";
import analyticsService from "@/commons/service/analyticsService";

export default {
  name: "ProductListSlider",
  props: {
    productList: { type: Array, required: false },
    query: { type: String, required: false },
    fetchCategoryId: { type: Number, required: false },
    categoryId: { type: Number, required: false },
    categoryPath: { type: String, required: false },
    productId: { type: Number, required: false },
    layout: { type: Number, required: false },
    shuffle: { type: Boolean, required: false, default: false },
    promo: { type: Boolean, required: false, default: false },
    limit: { type: Number, required: false, default: 12 },
    title: { type: String, required: false },
    proposal: { type: Object, required: false },
    paginationClass: { type: String, required: false },
    cols: { default: 1 },
    sm: { default: 2 },
    md: { default: 3 },
    lg: { default: 4 },
    xl: { default: 6 },
    showArrows: { type: Boolean, default: false },
    showBullets: { type: Boolean, default: true }
  },
  components: { ProductCard },
  data() {
    return {
      products: [],
      showMore: false,
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `#product-list-pagination-${this.paginationClass}`,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev-${this.paginationClass}`,
          nextEl: `#product-list-slider-next-${this.paginationClass}`
        },
        breakpoints: {
          0: {
            slidesPerView: this.cols
          },
          600: {
            slidesPerView: this.sm
          },
          960: {
            slidesPerView: this.md
          },
          1264: {
            slidesPerView: this.lg
          },
          1900: {
            slidesPerView: this.xl
          }
        }
      }
    };
  },
  async created() {
    // fetch the data when the view is created and the data is
    // already being observed
    await this.fetchProducts();
    if (!this.products || this.products.length < 1) {
      this.$emit("emptyslider");
    }
  },
  computed: {
    hasFirstCardSlot() {
      return !!this.$slots.firstCard;
    }
  },
  methods: {
    stopAutoplay() {
      this.$refs.swiperRef.swiperInstance.autoplay.stop();
    },
    startAutoplay() {
      if (!this.$refs.swiperRef.swiperInstance.autoplay.running) {
        this.$refs.swiperRef.swiperInstance.autoplay.start();
      }
    },
    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        this.position,
        payload.index
      );
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        analyticsService.viewProducts(this.products, this.position);
      }
    },
    async fetchProducts() {
      this.products = [];
      if (this.productList) {
        this.products = this.productList;
      } else if (this.query) {
        let response = await ProductService.search({
          q: this.query,
          page_size: 16
        });
        this.products = response.products;
      } else if (this.fetchCategoryId) {
        let response = await ProductService.search({
          parent_category_id: this.fetchCategoryId,
          category_id: this.categoryId,
          page_size: this.limit
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
      } else if (this.proposal) {
        let response = await ProductService.search({
          parent_category_id: this.proposal.categoryProposedId,
          page_size: this.limit
        });
        this.products = response.products;
        this.showMore = response.page.totItems > response.page.itemsPerPage;
      } else {
        let response = await ProductService.adv({
          layout: this.layout,
          product_id: this.productId,
          category_id: this.categoryId,
          limit: this.limit,
          shuffle: this.shuffle,
          promo: this.promo
        });
        if (response) {
          this.products = response.products;
        }
        this.$emit("loaded", this.products ? this.products.length : 0);
      }
    }
  }
};
</script>
